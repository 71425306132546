import { useRef, useContext } from "react";
import PersonIcon from "@mui/icons-material/PersonOutline";
import LockIcon from "@mui/icons-material/LockClockOutlined";
import { Link } from "react-router-dom";
import {ValidUserContext} from "./authCheck";
import "./index.css";

function Login() {
  const validUserContext = useContext(ValidUserContext);
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();
    validUserContext.apiAuthCheck(
      emailInputRef.current.value,
      passwordInputRef.current.value
    );
  };

  return (
    <div className={"login-page"}>
      <div>
        <title className={"loginTitle"}>Bem-vindo!</title>
        <div className="subtitle">Preencha os dados de acesso à sua conta de BackOffice, por favor.</div>
      </div>

      <form onSubmit={submitHandler} className={"form"}>
        <div>
        <PersonIcon className={"icon"} />

          <input
            className={"input"}
            type="email"
            id="user-name"
            name="user-name"
            autoComplete="on"
            placeholder="Nome de utilizador ou E-mail"
            ref={emailInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>

        <div>
        <LockIcon className={"icon"} />

          <input
            className={"input"}
            type="password"
            id="user-password"
            name="user-password"
            autoComplete="off"
            placeholder="Password"
            ref={passwordInputRef}
            required={!validUserContext.isLoggedIn}
            
          ></input>
        </div>

        <div className="forgot-password">
          <Link to="/forgot-password" className="forgot-password-link">
            Esqueci-me da palavra-passe
          </Link>
        </div>

        <button
          className={"loginBtn"}
          disabled={validUserContext.isLoggedIn}
        >
          {validUserContext.isLoggedIn ? "Already logged in" : "Login"}
        </button>
      </form>
    </div>
  );
}

export default Login;