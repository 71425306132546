import React from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Overlay = ({
  isOpen,
  onClose,
  title,
  children,
  isMobileSize = "100%",  // Responsive width for mobile
  defaultSize = "90%",    // Default size for larger screens
  maxHeight = "78vh"      // Default height
}) => {
  const isMobile = useMediaQuery("(max-width: 750px)");

  if (!isOpen) return null; // Return null if the overlay is not open

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          background: "#fff", // You can also pass this as a prop or use a theme
          padding: isMobile ? "10px" : "20px",
          width: isMobile ? isMobileSize : defaultSize,
          maxWidth: isMobile ? isMobileSize : "700px",
          height: isMobile ? "100vh" : maxHeight,
          position: "relative",
          overflowY: "scroll",
        }}
      >
        <Box
          p={2}
          transition="flex 0.3s ease"
          overflow="auto"
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <h2 style={{ color: "#000" }}>{title}</h2>
            <IconButton onClick={onClose} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box mt={2}>{children}</Box>
        </Box>
      </div>
    </div>
  );
};

export default Overlay;
