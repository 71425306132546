import React, { useState, useEffect, useContext } from "react";
import { Box, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { ValidUserContext } from "../../login/authCheck";
import Form from "../documentDetails/index"; // Import the form for document editing
import AddDocumentForm from "../createAEDocument";
import SnackbarComponent from "../../global/Snackbar";
import TableWithPagination from "../../global/Table"; // Custom table with pagination
import Overlay from "../../global/Overlay";


const AEDocuments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null); 
  const [isEditable, setIsEditable] = useState(false);
  const [isCreatingDocument, setIsCreatingDocument] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [overlayOpen, setOverlayOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 750px)");

  // Fetch documents from the API
  const fetchDocuments = async () => {
    const response = await fetch("https://projetouniversitas-evora.appspot.com/ae");
    const rawData = await response.json();
    const data = rawData.map((doc) => ({
      ...doc,
      id: doc.ID, // Keeping the ID for internal use
    }));
    setDocuments(data);
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const renderFileLink = (row) => {
    const fileUrl = row.FileURL;
    return (
      <a href={fileUrl} target="_blank" rel="noopener noreferrer">
        <PictureAsPdfIcon style={{ color: 'red', fontSize: 30 }} />
      </a>
    );
  };

  // Define table columns
  const columns = [
    { label: "ID", field: "ID", hideAlways:true }, 
    { label: "Nome do Documento", field: "Title" },
    { label: "Data de Publicação", field: "Publication_Date", },
    {
      label: "Ficheiro",
      field: "FileURL",
      hideAlways:true
    },
  ];


  // Handle opening document details for editing
  const handleOpenDocumentDetails = (document) => {
    setSelectedDocument(document);
    setIsCreatingDocument(false); // Ensure that it's not creating mode
    setOverlayOpen(true);
  };

  // Handle creating a new document
  const handleCreateDocument = () => {
    setSelectedDocument(null);
    setIsCreatingDocument(true);
    setOverlayOpen(true);
  };

  // Close the overlay
  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedDocument(null);
    setIsCreatingDocument(false);
    setIsEditable(false);
  };

  // Show a snackbar message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Check if the user can add documents
  const canAddDocuments = userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box width="100%">
      <Header title="Documentos" subtitle="Lista de todos os documentos" />


      {/* Table with pagination */}
      <TableWithPagination
        columns={columns}
        rows={documents}
        onRowClick={handleOpenDocumentDetails} 
        onButtonClick={handleCreateDocument}
        canAddElements={canAddDocuments}
        buttonTitle={"DOCUMENTOS"}

      />

    <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingDocument ? "Criar Documento" : "Editar Documento"}
      >
        {selectedDocument ? (
         <Form
         documentDetails={selectedDocument}
         onClose={closeOverlay}
         refreshDocuments={fetchDocuments}
         isEditable={isEditable}
         setIsEditable={setIsEditable}
         showSnackbar={showSnackbar}
       />
     ) : (
       <AddDocumentForm
         onClose={closeOverlay}
         refreshDocuments={fetchDocuments}
         showSnackbar={showSnackbar}
       />
        )}
      </Overlay>

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default AEDocuments;
