import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import { ValidUserContext } from "../../login/authCheck";
import ContactDetails from "../contactDetails/index";
import Form from "../createContact/index"; 
import SnackbarComponent from "../../global/Snackbar";
import TableWithPagination from "../../global/Table";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Overlay from "../../global/Overlay";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null); // State for the selected contact
  const [isCreatingContact, setIsCreatingContact] = useState(false); 
  const [isEditable, setIsEditable] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [overlayOpen, setOverlayOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 750px)");


  const fetchContacts = async () => {
    try {
      const response = await fetch(
        "https://projetouniversitas-evora.appspot.com/contacts",
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      setContacts(data.map((item, index) => ({ ...item, id: index })));
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const handleOpenContactDetails = (contact) => {
    setSelectedContact(contact);
    setIsCreatingContact(false); 
    setOverlayOpen(true);
  };

  const handleCreateContact = () => {
    setSelectedContact(null);
    setIsCreatingContact(true);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedContact(null);
    setIsCreatingContact(false);
    setIsEditable(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddContacts = userRoles.includes("SU") || userRoles.includes("ADMIN");

  // Define table columns
  const columns = [
    { label: "Full Name", field: "Full_Name" },
    { label: "Email", field: "Email",  },
    { label: "Phone Number", field: "Phone_Number", hideOnMobile: true },
  ];

  return (
    <Box width="100%">
      <Header title="Contactos" subtitle="Listagem de todos os contactos" />
  

      <TableWithPagination
        columns={columns}
        rows={contacts}
        onRowClick={handleOpenContactDetails} // Handle row click for contact details
        canAddElements={canAddContacts}
        onButtonClick={handleCreateContact}
        buttonTitle={"CONTACTO"}

      />

 <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingContact ? "Criar Serviço" : "Editar Serviço"}
      >
      {selectedContact ? (
                <ContactDetails
                  contactDetails={selectedContact}
                  onClose={closeOverlay}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  refreshContacts={fetchContacts}
                  showSnackbar={showSnackbar}
                />
              ) : (
                <Form
                  onClose={closeOverlay}
                  refreshContacts={fetchContacts}
                  showSnackbar={showSnackbar}
                />
              )}
      </Overlay>

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Contacts;
