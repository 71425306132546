import ReactLoading from "react-loading";

function LoadingPage() {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'bg-neutral-900'
        }}>
            <ReactLoading type="cylon" color="#452f87" height={100} width={50} />
        </div>
    );
}

export default LoadingPage;