import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const AddDocumentForm = ({ onClose, refreshDocuments, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleChanges = (newFile) => {
    setFile(newFile);
  };

  const handleFormSubmit = async (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("file", file);
    const url = "https://projetouniversitas-evora.appspot.com/ae/add";

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.text();
      refreshDocuments();
      showSnackbar("Documento adicionado com sucesso!"); 
      onClose();  
    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitting(false); 
  };


  return (
    <Box m="20px"
    >
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              gap="20px"
              flexDirection="column"

            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome"

                placeholder="Insira o nome do documento"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}

              />

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon sx={{ color: "#000" }} /> {/* Ícone de anexo em preto */}
                    </InputAdornment>
                  ),
                }}

                placeholder="Carregar Ficheiro"
                value={file}
                onChange={handleChanges}
              />

            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem",
                  fontWeight:600,
                  backgroundColor: colors.customTheme.primary,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: colors.customTheme.primary,
                    color: "#fff",
                    opacity: 0.7,
                  },
                }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar documento..." : "Criar Documento"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
});

const initialValues = {
  title: "",
  file: null, // Initialize the file as null
};

export default AddDocumentForm;
