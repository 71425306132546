import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Button, IconButton, useMediaQuery,useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { ValidUserContext } from "../../login/authCheck";
import NewsDetails from "../newsDetails";
import Form from "../createNews";
import CloseIcon from "@mui/icons-material/Close";
import { parse } from "date-fns";
import { pt } from "date-fns/locale";
import SnackbarComponent from "../../global/Snackbar";
import TableWithPagination from "../../global/Table"; // Custom table with pagination
import Overlay from "../../global/Overlay";


const News = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userRoles } = useContext(ValidUserContext);
  const [newsData, setNewsData] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [isCreatingNews, setIsCreatingNews] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const fetchNewsCalled = useRef(false);
  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [overlayOpen, setOverlayOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 750px)");


  useEffect(() => {
    if (!fetchNewsCalled.current) {
      fetchNews();
      fetchNewsCalled.current = true;
    }
  }, []);

  const parsePublicationDate = (dateString) => {
    // console.log(dateString);

    return parse(dateString, "EEEE, MMMM d, yyyy - HH:mm", new Date(), {
      locale: pt,
    });
  };

  const fetchNews = () => {
    if (isFetching) return;

    setIsFetching(true);
    let uri = "https://projetouniversitas-evora.appspot.com/news";
    // if (!fetchNewsCalled.current) uri += "?amount=11";
    // else uri += "?amount=5";
    // if (cursor != null) uri += "&cursor=" + cursor;

    fetch(uri)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then((data) => {
       // // console.log(data);
       setNewsData(
        data.news.map((item) => ({
          ...item,
          id: item.ID,
        }))
      );
       // setCursor(data.cursor);
      })
      .catch((error) => console.error("There was an error!", error))
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleOpenNewsDetails = (news) => {
    // Ensure that news details are opened instead of the creation form
    setSelectedNews(news); // Set the selected news item
    setIsCreatingNews(false); // Not in creation mode
    setOverlayOpen(true); // Open the overlay
  };

  const handleCreateNews = () => {
    setSelectedNews(null);
    setIsCreatingNews(true);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedNews(null);
    setIsCreatingNews(false);
    setIsEditable(false);
  };

  const columns = [
    { label: "ID", field: "ID", hideAlways: true }, // ID hidden on mobile
   
    { label: "Título", field: "Title" },
    { label: "Autor", field: "Author", hideOnMobile: true },
    {
      label: "Data de Publicação",
      field: "Publication_Date",
      render: (row) => parsePublicationDate(row.Publication_Date).toLocaleDateString(),
      hideOnMobile:true,
    }, {
      label: "Fotografia",
      field: "ImageURL",
      isImage: true, // Adicionando o atributo isImage para esta coluna

    },
  ];


  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddNews =
    userRoles.includes("SU") ||
    userRoles.includes("ADMIN") ||
    userRoles.includes("News");

    return (
      <Box width="100%">
        <Header title="Notícias" subtitle="Listagem de todas as notícias" />
       
  
        {/* Table with pagination */}
        <TableWithPagination
          columns={columns}
          rows={newsData}
          onRowClick={handleOpenNewsDetails} // Open news details on row click
          onButtonClick={handleCreateNews}
          canAddElements={canAddNews}
          buttonTitle={"NOTÍCIA"}

        />
       <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingNews ? "Criar Notícia" : "Detalhes da Notícia"}
      >
        {selectedNews ? (
         <NewsDetails
         newsDetails={selectedNews}
         onClose={closeOverlay}
         refreshNews={fetchNews}
         isEditable={isEditable} // Passa o estado atual para o formulário
         setIsEditable={setIsEditable}
         showSnackbar={showSnackbar} // Passa a função aqui
       />
     ) : (
       <Form
         onClose={closeOverlay}
         refreshNews={fetchNews}
         showSnackbar={showSnackbar}
       />
        )}
      </Overlay>
    
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default News;
